import { Dispatch } from 'redux';

import { action } from '../tools/reduxTools';
import { Http } from '../tools/http';
import { IState } from '../reducers';
import { ELcid } from '../entities/ILanguage';
import { ISearchResponse } from '../entities/IGlobal';
import { ICategoriesData } from '../components/Organization/Settings/Tabs/Categories';
import { ISingleCategory } from '../components/Common/Panel/PanelTypes/Categories';
import { ILimitsData } from '../components/Organization/Settings/Tabs/Limits';
import { ISingleLimit } from '../components/Common/Panel/PanelTypes/Limits';
import { ISinglePeriod } from '../components/Common/Panel/PanelTypes/Periods';
import { IPeriodsData } from '../components/Organization/Settings/Tabs/Periods';
import * as actionTypes from './actionTypes/workspaceTypes';

/** Categories */

export const getAllCategories = (organizationId: string) => action<Promise<ISearchResponse<ICategoriesData>>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiPost(`/organizations/${organizationId}/categories/as-datatable?lcid=${language.userLanguage || ELcid.En}`).then((res: ISearchResponse<ICategoriesData>) => {
        dispatch({
            type: actionTypes.SET_CATEGORIES,
            categories: res.items
        });
        return res;
    }).catch(() => console.log('error'));
});

export const getSingleCategory = (organizationId: string, categoryId: string) => action<Promise<ISingleCategory>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/organizations/${organizationId}/categories/${categoryId}`);
});

export const createSingleCategory = (organizationId: string, body: ISingleCategory) => action<Promise<ISingleCategory>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/categories`, body);
});

export const deleteSingleCategory = (organizationId: string, categoryId: string) => action<Promise<boolean>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/organizations/${organizationId}/categories/${categoryId}`);
});

export const updateSingleCategory = (organizationId: string, categoryId: string, body: ISingleCategory) => action<Promise<ISingleCategory>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${organizationId}/categories/${categoryId}`, body);
});

/** Limits */

export const getAllLimits = (organizationId: string, page: number) => action<Promise<ISearchResponse<ILimitsData>>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiPost(`/organizations/${organizationId}/limits/as-datatable?page=${page}&size=50&lcid=${language.userLanguage || ELcid.En}`);
});

export const getSingleLimit = (organizationId: string, limitId: string) => action<Promise<ISingleLimit>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/organizations/${organizationId}/limits/${limitId}`);
});

export const createSingleLimit = (organizationId: string, body: ISingleLimit) => action<Promise<ISingleLimit>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/limits`, body);
});

export const deleteSingleLimit = (organizationId: string, limitId: string) => action<Promise<boolean>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/organizations/${organizationId}/limits/${limitId}`);
});

export const updateSingleLimit = (organizationId: string, limitId: string, body: ISingleLimit) => action<Promise<ISingleLimit>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${organizationId}/limits/${limitId}`, body);
});

/** Periods */

export const getAllPeriods = (organizationId: string) => action<Promise<ISearchResponse<IPeriodsData>>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiPost(`/organizations/${organizationId}/periods/as-datatable?lcid=${language.userLanguage || ELcid.En}`);
});

export const getSinglePeriod = (organizationId: string, periodId: string) => action<Promise<ISinglePeriod>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/organizations/${organizationId}/periods/${periodId}`);
});

export const createSinglePeriod = (organizationId: string, body: ISinglePeriod) => action<Promise<ISinglePeriod>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/periods`, body);
});

export const deleteSinglePeriod = (organizationId: string, periodId: string) => action<Promise<boolean>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/organizations/${organizationId}/periods/${periodId}`);
});

export const updateSinglePeriod = (organizationId: string, periodId: string, body: ISinglePeriod) => action<Promise<ISinglePeriod>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${organizationId}/periods/${periodId}`, body);
});