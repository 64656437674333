import React, { useEffect, useLayoutEffect, useRef, useState, ReactNode, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { faCaretDown, faCaretUp, faChevronDown, faEllipsis, faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ESortTypes } from '../../../entities/AssetManagement/global';
import { EKeysForDataTableExport, ENamesForDataTableExport, ITemplateDataGridSettings } from '../../../entities/IClusters';
import { EColoredDatagridField, EColoredDatagridViewParentTypeValue } from '../../../entities/IDatagrid';
import { NoData } from '../NoData/NoData';
import { Header } from './Header';
import { SingleRow } from './SingleRow';
import { EDirectionType, EOrderByType, getFieldName, getOrderByType } from '../../../entities/AssetManagement/actions';
import { FilterBar, IMainDatatableFiltersData } from './FilterBar/FilterBar';
import { colorStack } from '../../../styleHelpers/colors';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { useAlert, useDropdown } from '../../../tools/hooks';
import { Switch } from '../Switch/Switch';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { ContextMenu, ISingleLink } from '../ContextMenu/ContextMenu';
import { exportDatagrid } from '../../../actions/commonActions';
import { EAlertType } from '../../../entities/IAlert';
import { IState } from '../../../reducers';
import { IConfigReducer } from '../../../reducers/configReducer';
import { ELegalClusterEnv } from '../../../entities/IConfiguration';
import { IItem } from '../../../entities/IFilters';
import { ConfirmPopup, IProps as IConfirmPopupProps } from '../ConfirmPopup/ConfirmPopup';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import { createTableView, getTableView, ITableViewSettings } from '../../../actions/clustersActions';
import { UrlInput } from '../UrlInput';
import { clearBackgroundDatatable, setBackgroundDatatableIds } from '../../../actions/datatableActions';
import IntlMessage, { useIntlMessage } from '../IntlMessage';
import { EBackgroundDatatableName } from '../../../reducers/datatableReducer';
import { EColumnSubTypes } from '../../../tools/dataGridTools';

type ExportDatagrid = ReturnType<typeof exportDatagrid>;
type CreateTableView = ReturnType<typeof createTableView>;
type GetTableView = ReturnType<typeof getTableView>;
type SetBackgroundDatatableIds = ReturnType<typeof setBackgroundDatatableIds>;
type ClearBackgroundDatatable = ReturnType<typeof clearBackgroundDatatable>;

const VIEW_URL_PARAM = 'view';

interface CopyUrlModalProps<T1, T2> extends Partial<IConfirmPopupProps> {
    showPopup: boolean;
    filters: T2;
    tableType: string;
    parentId: string;
    parentTypeValue: string;
    dataSourceUrl: string;
    tableSettingsId: string;
    staticSelected: IItem[];
    filtersData: T1;
    preprocessFilters?(filters: T2);
    postprocessFilters?(filters: T2);
}

const copyUrlReplacementRegex = new RegExp(`\\?${VIEW_URL_PARAM}=[^&]*`);
const CopyUrlModal = function <T1 extends object, T2 extends object>(props: CopyUrlModalProps<T1, T2>) {
    const dispatch = useDispatch();
    const [copyUrl, setCopyUrl] = useState<string>(undefined);

    useEffect(() => {
        const finalFilters = props.preprocessFilters?.(props.filters) || props.filters;

        for (let key in finalFilters) {
            if (Array.isArray(finalFilters[key])) {
                finalFilters[key].sort();
            }
        }

        const finalStaticSelected = props.staticSelected?.slice();
        finalStaticSelected.sort((a: { text: string }, b: { text: string }) => {
            return a.text.localeCompare(b.text);
        });

        dispatch<CreateTableView>(createTableView<T1, T2>({
            dataSourceUrl: props.dataSourceUrl,
            tableParams: {
                api: finalFilters,
                ui: {
                    staticSelected: finalStaticSelected,
                    filtersData: props.filtersData
                }
            },
            tableType: props.tableType,
            parentId: props.parentId,
            tableSettingsId: props.tableSettingsId,
            parentTypeValue: props.parentTypeValue
        })).then(data => {
            setCopyUrl(`${window.location.href.replace(window.location.hash, '').replace(copyUrlReplacementRegex, '')}?${VIEW_URL_PARAM}=${data.id}${window.location.hash}`);
        });
    }, [props.filters]);

    return (
        <ConfirmPopup {...props} hideButtonWithNoHandlerFunction>
            {copyUrl ?
                <UrlInput value={copyUrl} doneCallback={props.noHandler} />
                : <Spinner size={ESpinnerSize.SMALL} />
            }
        </ConfirmPopup>
    );
};

const MenuWrapper = styled.div<{}>`
    position: relative;
`;

const ThreeDotsMenu = styled.div<{}>`
    display: flex;
    ${fontSizeAndHeight[25]}
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
        color: ${colorStack.darkGrey};
        transform: rotate(90deg);
    }
`;

const Container = styled.div<{ filterHeight: number, background?: string, separator?: boolean }>`
    overflow: auto;
    flex: 1 1 auto;
    height: 100%;
    ${({ separator }) => separator && css`
        border-top: 2px solid ${colorStack.bodyBg};
        padding-top: 8px;
    `
    }
    ${({ filterHeight }) => !!filterHeight && css`
        height: ${`calc(100% - ${filterHeight - 4}px)`};
    `}
    scrollbar-gutter: stable;
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border: solid 1px transparent;
        background-clip: padding-box;
        background-color: ${colorStack.middleGrey};
        border-radius: 4px;
    }
`;

export const Table = styled.table`
    table-layout: auto;
    width: fit-content;
    min-width: 99%;
    border-spacing: 0;
    border-collapse: separate;
    border-spacing: 0 4px;
    margin: 0 2px;
    tbody tr {
        box-shadow: 0 1px 6px 0px #00000010;
        border-radius: 8px;
        td:not(:last-child) {
            border-right: 1px solid #00000010;
        }
        td:first-child {
            border-radius: 8px 0 0 8px;
        }
        td:last-child, td:last-child > span {
            border-radius: 0 8px 8px 0;
            overflow: hidden;
        }
    }
`;

const TitleBar = styled.div`
    padding: 1rem 0;
    display: flex;
    height: 66px;
    justify-content: space-between;
    position: relative;
    align-items: center;
    &::after {
        display: block;
        border-radius: 20px;
        width: 100%;
        height: 3px;
        background: ${colorStack.bodyBg};
        content: '';
        position: absolute;
        bottom: 0;
    }
`;

const Content = styled.div<{ orgContext?: boolean, withoutMinHeight?: boolean }>`
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 66px);
    ${props => !props.withoutMinHeight &&
        css`
            min-height: 59vh;
        `
    }
`;

const Title = styled.div`
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${fontSizeAndHeight[20]};
    font-weight: 500;
    color: ${colorStack.content};
    svg {
        color: ${colorStack.ligthGrey};
        ${fontSizeAndHeight[25]}
        margin-right: 1rem;
    }
`;

const ButtonsBox = styled.div`
    display: flex;
    gap: 1rem;
`;

const FilterSwitch = styled(Switch)`
    flex-shrink: 0;
    margin-left: auto;
    padding: 8px 0 0 0;
`;

const MiddleContent = styled.div`
    margin: 1rem 0;
`;

interface IOrderByFilter {
    orderBy?: EOrderByType;
    direction?: EDirectionType;
}

function isSortableFilter(filters: unknown): filters is IOrderByFilter {
    return typeof filters === 'object' && (Object.hasOwn(filters, 'orderBy') || Object.hasOwn(filters, 'direction'));
}

export interface IMainColoredDatatableFiltersData extends IMainDatatableFiltersData { }

interface IColoredDatagridProps<T1 extends object, T2 extends object> {
    title?: ReactNode;
    exportMenu?: ISingleLink[];
    noGenericExport?: boolean;
    exportButtonTranslationKey?: string;
    background?: string;
    separator?: boolean;
    isCollapsed?: boolean;
    tableType?: string;
    orgContext?: boolean;
    coloredFieldName?: EColoredDatagridField;
    coloredRowSchema?: EColoredDatagridField;
    sortedFields?: string[];
    withoutMinHeight?: boolean;
    collapsable?: boolean;
    shareable?: boolean;
    forceShareable?: boolean;
    filteringFields?: string[];
    filtersData?: T1;
    filters?: T2;
    backgroundDatatableName?: EBackgroundDatatableName;
    exportData?: {
        urlForExport?: string;
        exportKey?: EKeysForDataTableExport;
        nameOfDatatable?: ENamesForDataTableExport;
        resourceName: string;
        resourceType: EColoredDatagridViewParentTypeValue;
    }
    exportMenuActionPending?: boolean;
    tableViewData?: {
        parentId: string;
        parentTypeValue: string;
        tableType: string;
    }
    resizableColumns?: boolean | number;
    outsideButtons?: React.ReactNode;
    removedRowId?: number;
    createButtonDisabled?: boolean;
    whiteHeader?: boolean;
    middleContent?: ReactNode;
    createHandler?: () => void;
    getSettings();
    preprocessFilters?(filters: T2);
    postprocessFilters?(filters: T2);
    getData(currentPage?: number, perpage?: number, _filters?): Promise<unknown[]>;
    onClick?(id: string, clusterId: string);
    setFilter?(field: string, id: string[]);
    setFilters?(value: T2 | React.Dispatch<React.SetStateAction<T2>>): void;
    createFiltersData?(data: ({ [key: string]: any }[]) | Record<string, any[]>): void;
    resolveTopicFromField?(field: string): string | void;
    clearAllFilters?();
}

export const ColoredDatagrid = <T1 extends object = Record<string, IMainColoredDatatableFiltersData[]>, T2 extends object = {}>(props: IColoredDatagridProps<T1, T2>) => {
    const arrayChunk = 40;
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const viewId = params.get(VIEW_URL_PARAM);
    const { organization } = useParams<{ organization: string; }>();
    const { platformName, isLocalHost } = useSelector<IState, IConfigReducer>(state => state.config);
    const [settings, setSettings] = useState<ITemplateDataGridSettings>({
        userId: undefined,
        dataOrders: undefined,
        columnsSettings: undefined,
        id: undefined,
        clusterTemplateId: undefined,
        format: undefined,
        clusterCustomDataGridConfigurationId: undefined,
        key: props?.exportData?.exportKey
    });
    const [finalData, setFinalData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [fullyLoaded, setFullyLoaded] = useState<boolean>(false);
    const [fetchingSettings, setFetchingSettings] = useState<boolean>(false);
    const [directions, setDirections] = useState<Record<string, ESortTypes>>(undefined);
    const [filterHeight, setFilterHeight] = useState<number>(0);
    const [showFilters, setShowFilters] = useState<boolean>(!!viewId);
    const [wrapperRef, dropdownOpen, toggleDropdown] = useDropdown();
    const [dotsMenuWrapperRef, dotsMenuDropdownOpen, toggleDotsMenuDropdown] = useDropdown();
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [staticSelected, setStaticSelected] = useState<IItem[]>([]);
    /* const [showSharePopup, setShowSharePopup] = useState<boolean>(false); */
    const [showCopyUrlPopup, setShowCopyUrlPopup] = useState<boolean>(false);
    const [preventCopyUrl, setPreventCopyUrl] = useState<boolean>(false);
    const [loadingDataTableView, setLoadingDataTableView] = useState<boolean>(false);
    const [isInfiniteLoaderAvailable, setIsInfiniteLoaderAvailable] = useState<boolean>(false);

    const [collapsed, setCollapsed] = useState<boolean>(false);

    useEffect(() => {
        setCollapsed(props.isCollapsed);
    }, [props.isCollapsed]);

    const onHeaderClick = useCallback(() => {
        setCollapsed(currentState => !currentState);
    }, []);

    const share = useCallback(() => {
        // @TODO implement as next task for datatable views
    }, [props.filters]);
    const threeDotsMenuActions: ISingleLink[] = useMemo(() => {
        const actions: ISingleLink[] = [];

        actions.push({
            name: intlFormatMessage({ id: 'global.share' }),
            visible: false && props.shareable, // false will be removed once it is ready
            action: () => {
                /* setShowSharePopup(true); */
                toggleDotsMenuDropdown();
            }
        });

        actions.push({
            name: intlFormatMessage({ id: 'global.copyUrl' }),
            visible: props.shareable,
            disabled: preventCopyUrl,
            action: () => {
                setShowCopyUrlPopup(true);
                toggleDotsMenuDropdown();
            }
        });

        actions.push({
            name: intlFormatMessage({ id: 'filters.datatable.export' }),
            visible: !props.noGenericExport && !!props.exportData,
            disabled: exportLoading,
            action: () => {
                coloredDatatableExport();
                toggleDotsMenuDropdown();
            }
        });

        return actions;
    }, [props.shareable, exportLoading, toggleDotsMenuDropdown, preventCopyUrl]);

    // tslint:disable-next-line:no-null-keyword
    const observableNode = useRef<HTMLDivElement>(null);
    // tslint:disable-next-line:no-null-keyword
    const observer = useRef<IntersectionObserver | null>(null);
    const didMountRef = useRef<boolean>(false);

    useEffect(() => {
        if (props.removedRowId !== undefined) {
            setFinalData(state => state.filter(elem => elem.datatableElemId !== props.removedRowId));
        }
    }, [props.removedRowId]);

    useEffect(() => {
        if (!props.backgroundDatatableName) {
            return;
        }
        const newIds = [];

        if (finalData) {
            newIds.push(...finalData.map(row => row.id));
        }

        dispatch<SetBackgroundDatatableIds>(setBackgroundDatatableIds(props.backgroundDatatableName, newIds));

        return () => {
            dispatch<ClearBackgroundDatatable>(clearBackgroundDatatable(props.backgroundDatatableName));
        };
    }, [finalData]);

    const coloredDatatableExport = () => {
        setExportLoading(true);
        const preprocessedFilters = props.preprocessFilters ? props.preprocessFilters(props?.filters) : props?.filters;
        const body = {
            // tslint:disable-next-line:no-string-literal
            dataSourcePayload: preprocessedFilters ? { ...preprocessedFilters, query: (preprocessedFilters['query']?.[0] || '') } : {},
            dataSourceUrl: props?.exportData?.urlForExport,
            resourceType: props?.exportData?.resourceType,
            resourceName: props?.exportData?.resourceName,
            organizationContext: organization,
            settings: {...settings, exportable: true},
            dataTableContext: props?.exportData?.nameOfDatatable,
            // tslint:disable-next-line:no-string-literal
            filtersUserFriendlyText: props?.filters ? {
                // tslint:disable-next-line:no-string-literal
                query: (props?.filters?.['query']?.[0] || ''), ...staticSelected?.reduce((acc, item) => ({
                    ...acc,
                    [intlFormatMessage({ id: `filters.map.${item.field}` })]: acc[intlFormatMessage({ id: `filters.map.${item.field}` })] ? `${acc[intlFormatMessage({ id: `filters.map.${item.field}` })]}, ${item.text}` : item.text
                }), {})
            } : {}
        };

        addAlert(<IntlMessage id="filters.datatable.export.alert.started" />, EAlertType.SUCCESS);

        return dispatch<ExportDatagrid>(exportDatagrid(body)).then(() => {
            setExportLoading(false);
            addAlert(<IntlMessage id="filters.datatable.export.alert.success" />, EAlertType.SUCCESS);
        }).catch((error) => {
            if (isLocalHost || platformName === ELegalClusterEnv.lcDev) {
                addAlert(JSON.parse(error).message, EAlertType.ERROR);
            } else {
                addAlert(<IntlMessage id="filters.datatable.export.alert.error" />, EAlertType.ERROR);
            }
            setExportLoading(false);
        });
    };

    const createData = async () => {
        if (loading || loadingDataTableView) {
            return;
        }

        try {
            setCurrentPage(0);
            setLoading(true);
            setFinalData(undefined);
            setPreventCopyUrl(true);
            const res = await props.getData(0, arrayChunk);
            setFinalData(res);
            setCurrentPage(page => page + 1);
            if (res.length < arrayChunk) {
                setFullyLoaded(true);
            } else {
                setFullyLoaded(false);
            }
        } catch (error) {
            console.log(error);
            setFinalData([]);
            setLoading(false);
        } finally {
            setLoading(false);
            setPreventCopyUrl(false);
        }
    };

    const changeOrder = (order: ESortTypes, field: string) => {
        props.setFilters((currentFilters) => ({
            ...currentFilters,
            orderBy: order !== ESortTypes.None ? getOrderByType(field) : EOrderByType.UpdatedOrCreatedDate,
            direction: EDirectionType[order]
        }));
    };

    useEffect(() => {
        if (!loadingDataTableView && props.sortedFields?.length && isSortableFilter(props.filters)) {
            const filter: IOrderByFilter = props.filters;
            setDirections(Object.fromEntries(props.sortedFields.map(field => [
                field,
                getFieldName(filter.orderBy) === field && ESortTypes[EDirectionType[filter.direction]] || ESortTypes.None
            ])));
        }
    }, [props.sortedFields, props.filters, loadingDataTableView]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (didMountRef.current) {
            createData();
        }
    }, [props.filters]);

    useEffect(() => {
        if (!fetchingSettings) {
            props.getSettings()?.then(res => {
                setSettings((prevState) => ({ ...prevState, ...res }));
                setFetchingSettings(false);
            });
        }

        if (viewId) {
            setLoadingDataTableView(true);
            setLoading(true);
            dispatch<GetTableView>(getTableView<T1, T2>(viewId)).then(async (tableView: ITableViewSettings<T1, T2>) => {
                if (tableView.tableParams?.api && tableView.tableParams?.ui) {
                    const {
                        api: apiFilters,
                        ui: uiFilters
                    } = tableView.tableParams;

                    props.setFilters(props.postprocessFilters ? props.postprocessFilters(apiFilters) : apiFilters);

                    if ('query' in apiFilters) {
                        props.setFilter?.('query', apiFilters.query[0]);
                    }

                    props.createFiltersData?.(uiFilters.filtersData as Record<string, any>);

                    const _finalData = await props.getData(0, arrayChunk, apiFilters);

                    setStaticSelected(uiFilters.staticSelected);
                    setFinalData(_finalData);

                    setFullyLoaded(true);
                } else {
                    addAlert(<IntlMessage id="datagrid.view.alert.notFound" />, EAlertType.ERROR);
                }
            }).finally(() => {
                setTimeout(() => {
                    setLoadingDataTableView(false);
                    setLoading(false);
                }, 2000);
            });
        } else {
            createData();
        }
        didMountRef.current = true;
    }, []);

    useLayoutEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && finalData?.length > 0 && !fullyLoaded && !loading) {
                setIsInfiniteLoaderAvailable(true);
                setLoading(true);
                setPreventCopyUrl(true);
                props.getData(currentPage, arrayChunk).then((data) => {
                    setFinalData(state => state ? [...state, ...data] : data);
                    setCurrentPage(page => page + 1);
                    if (data.length < arrayChunk) setFullyLoaded(true);
                }).finally(() => {
                    setLoading(false);
                    setPreventCopyUrl(false);
                });
            }
        }, {
            threshold: .35
        });
        (observer.current && observableNode?.current) && observer.current.observe(observableNode.current);

        return () => observer.current?.disconnect?.();
    }, [currentPage, observableNode, arrayChunk, setFinalData, loading, loadingDataTableView, fullyLoaded]);

    const loadingRow = <SingleRow data={undefined} settings={settings?.columnsSettings} coloredFieldName={props.coloredFieldName} coloredRowSchema={props.coloredRowSchema} loading />;
    const showStandardMenu = !!(props.title || props.createHandler || props.exportMenu || props.forceShareable);

    return (<>
        {showStandardMenu &&
            <TitleBar>
                <Title onClick={props.collapsable ? onHeaderClick : undefined}>
                    {props.collapsable && <FontAwesomeIcon icon={collapsed ? faCaretDown : faCaretUp} size="xs" />}
                    {props.title}
                </Title>
                <ButtonsBox ref={wrapperRef}>
                    {(props.filtersData && !!props.filteringFields?.length && showStandardMenu) &&
                        <Switch
                            activeText={intlFormatMessage({ id: `filters.datatable.switch` })}
                            changeHandler={(active) => {
                                setShowFilters(active);
                            }}
                            notActiveText={intlFormatMessage({ id: `filters.datatable.switch` })}
                            state={showFilters}
                        />
                    }
                    {props.exportMenu &&
                        <Button
                            onClick={toggleDropdown}
                            leftIco={faFileExport}
                            typeSchema={props.exportMenuActionPending ? EButtonTypeSchema.PRIMARY : EButtonTypeSchema.TEXT}
                            rightIco={faChevronDown}
                            disabled={props.exportMenuActionPending}
                            loading={props.exportMenuActionPending}
                        >
                            <IntlMessage id={props.exportButtonTranslationKey || 'global.export'} />
                            {dropdownOpen &&
                                <div onClick={toggleDropdown}>
                                    <ContextMenu
                                        links={props.exportMenu}
                                        openFromRight
                                        onClickOutside={toggleDropdown}
                                    />
                                </div>
                            }
                        </Button>}
                    {props.createHandler && <Button onClick={props.createHandler} disabled={props.createButtonDisabled} type="button"><IntlMessage id="global.create" /></Button>}
                    {props.outsideButtons}
                    {threeDotsMenuActions.filter(action => action.visible).length > 0 &&
                        <MenuWrapper ref={dotsMenuWrapperRef}>
                            <ThreeDotsMenu onClick={toggleDotsMenuDropdown}><FontAwesomeIcon icon={faEllipsis} scale={1.5} /></ThreeDotsMenu>
                            {dotsMenuDropdownOpen && <ContextMenu links={threeDotsMenuActions} openFromRight onClickOutside={toggleDotsMenuDropdown} />}
                        </MenuWrapper>
                    }
                </ButtonsBox>
            </TitleBar>
        }
        {props.middleContent && <MiddleContent>{props.middleContent}</MiddleContent>}
        {!collapsed &&
            <Content orgContext={props.orgContext} withoutMinHeight={props.withoutMinHeight}>
                {settings &&
                    <FilterBar
                        blockAction={loading || loadingDataTableView}
                        switch={!showStandardMenu && <FilterSwitch
                            activeText={intlFormatMessage({ id: `filters.datatable.switch` })}
                            changeHandler={(active) => {
                                setShowFilters(active);
                            }}
                            notActiveText={intlFormatMessage({ id: `filters.datatable.switch` })}
                            state={showFilters}
                        />}
                        staticSelected={staticSelected}
                        setStaticSelected={setStaticSelected}
                        showFilters={showFilters}
                        filteringFields={props.filteringFields}
                        filtersData={props.filtersData}
                        filters={props.filters}
                        setFilter={props.setFilter}
                        resolveTopicFromField={props.resolveTopicFromField}
                        setFilterHeight={setFilterHeight}
                    />
                }
                <Container filterHeight={filterHeight} background={props.background} separator={props.separator}>
                    {finalData?.length === 0 && !loading ?
                        (
                            <NoData>
                                <br />
                                <IntlMessage id="global.datagrid.noElements" />
                            </NoData>
                        ) : (settings && (
                            <Table>
                                <Header
                                    data={settings.columnsSettings}
                                    directions={directions}
                                    whiteHeader={props.whiteHeader}
                                    setDirection={changeOrder}
                                    hasColoredColumn={!!props.coloredFieldName || !!settings?.columnsSettings?.find(elem => elem.subType === EColumnSubTypes.Color)?.field}
                                />
                                <tbody>
                                    {finalData?.map(elem => <SingleRow
                                        key={elem.id}
                                        data={elem}
                                        settings={settings.columnsSettings}
                                        coloredFieldName={props.coloredFieldName}
                                        coloredRowSchema={props.coloredRowSchema}
                                        onClick={props.onClick}
                                    />
                                    )}
                                    {loading && (finalData?.length > 0 ? (isInfiniteLoaderAvailable && loadingRow) : (
                                        <>
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                            {loadingRow}
                                        </>
                                    ))}
                                </tbody>
                            </Table>)
                        )
                    }
                    <div ref={observableNode} />
                </Container>
            </Content>
        }
        {props.tableViewData && showCopyUrlPopup &&
            <CopyUrlModal<T1, T2>
                {...props.tableViewData}
                dataSourceUrl={props.exportData?.urlForExport}
                tableSettingsId={settings.id}
                title={intlFormatMessage({ id: 'global.copyUrl' })}
                showPopup
                filters={props.filters}
                staticSelected={staticSelected}
                filtersData={props.filtersData}
                preprocessFilters={props.preprocessFilters}
                noHandler={() => setShowCopyUrlPopup(false)}
            />
        }
    </>);
};
